import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import { type FC } from 'react'
import styled from 'styled-components'

import Button from '@base/buttons/Button'
import { ControlButtonsWrapper } from '@components/header/ControlButtons/styles'
import useIsMobile from '@hooks/mediaQueries/useIsMobile'

const ControlButtons = dynamic(() => import('@components/header/ControlButtons'))

type Props = {
  customerSupportLink?: string
}

const ControlButtonsFooter: FC<Props> = ({ customerSupportLink }) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  return (
    <Container>
      {!isMobile && (
        <ControlButtons isMobileMenuButton={false} placementRegionalMenu="topRight" placementTicketsMenu="topRight" />
      )}
      <ButtonCustom href={customerSupportLink} htmlType="button" type="text">
        {t('common.customerSupport')}
      </ButtonCustom>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 64px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 36px;
  justify-content: end;

  ${ControlButtonsWrapper} {
    grid-gap: 36px;
  }

  ${p => p.theme.mediaQueries.desktop} {
    grid-area: controlButtonsFooter;
    justify-content: space-between;
  }

  ${p => p.theme.mediaQueries.mobile} {
    display: flex;

    ${ControlButtonsWrapper} {
      display: none;
    }
  }
`

const ButtonCustom = styled(Button)`
  &.ant-btn {
    width: 214px;

    ${p => p.theme.mediaQueries.mobile} {
      grid-area: buttonCustom;
      width: 100%;
    }
  }
`
export default ControlButtonsFooter
