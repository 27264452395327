import { useTranslation } from 'next-i18next'
import type { FC } from 'react'
import { memo } from 'react'

import InfoItem from '@components/mainPage/mainBlock/searchTrains/info/InfoItem'
import { InfoWrapper } from '@components/mainPage/mainBlock/searchTrains/info/styles'

const Info: FC = () => {
  const { t } = useTranslation()

  return (
    <InfoWrapper>
      <InfoItem description={t('searchForm.info.support.description')} title={t('searchForm.info.support.title')} />
      <InfoItem description={t('searchForm.info.payment.description')} title={t('searchForm.info.payment.title')} />
      <InfoItem description={t('searchForm.info.timetable.description')} title={t('searchForm.info.timetable.title')} />
    </InfoWrapper>
  )
}

export default memo(Info)
